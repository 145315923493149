import React from "react";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import SectionTemplate from "../../components/SectionTemplate/Section.template";
import ReactMarkdown from "react-markdown";
import styled, { css } from "styled-components";
import { flexCenter, flexColumn } from "../../components/mixins";
import { getAllSizesPNG } from "../../utils/imageUtils";
import ResponsiveImage from "../../components/ResponsiveImage/ResponsiveImg.component";
import Button from "../../components/Button/Button.component";
import { scrollTo } from "../../utils/scrollTo";

// const ProgramWrapper = styled.div`
//     ${flexCenter};
//     justify-content: space-between;
//     color: ${({ theme }) => theme.colors.dark_grey};
//     max-width: 1000px;
//     margin: 0 auto;
//     padding: 0 30px;

//     @media screen and (max-width: 1000px) {
//         ${flexColumn};
//     }
// `;

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    
`

const BoxWrapper = styled.div`
    ${flexCenter};
    flex-direction:${({ position }) =>
    position === "right" ? `row-reverse;`
        : position === "top" ? `column;`
        : position === "bottom" ?  `column-reverse;`
        : `row;`}
    width:100%;
    margin: 10px auto 0;
    max-width: 1050px;
    font-size: 1.8rem;
    color: #555555;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 300;
    text-align:justify;

    @media screen and (max-width: 1025px) {
        flex-direction:${({ position }) =>
        position === "right" ? `column-reverse;`
            : position === "top" ? `column;`
            : position === "bottom" ?  `column-reverse;`
            : `column;`}
    }
`

const BoxTwo = styled.div`
    padding-left:40px;
    p,
    ul,
    ol {
        font-size: ${({ theme }) => theme.fz.S};
        font-weight: ${({ theme }) => theme.fw.light};

        @media screen and (max-width: 560px) {
            font-size: ${({ theme }) => theme.fz.XS};
        }
    }

    p {
        text-align: center;
        margin: 20px 0;
    }

    ul,
    ol {
        padding-left: 20px;
        list-style: auto;
        @media screen and (max-width: 560px) {
            text-align: left;
            padding-left: 20px;
        }
    }

    ul {
        list-style: none;
        list-style-type: none;
    }

    ol li,
    ul li {
        position: relative;
        margin: 20px 0;

        ul,
        ol {
            padding-left: 20px;
        }
    }
    

    ul li {
        &::before {
            position: absolute;
            top: 0.4em;
            left: 0;
            transform: translateX(-1.2em);
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.dark_grey};
        }
    }
`;

const BoxOne = styled.div`
    margin:0px 50px;
    & > picture {
        width: 280px;
        height: 280px;
        margin: 15px auto;

        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
            border-radius: 50%;
            border: 1px solid ${({ theme }) => theme.colors.black};
        }

        @media screen and (max-width: 1025px) {
            width: 210px;
            height: 210px;

            img {
                width: 210px;
                height: 210px;
            }
        }

        @media screen and (max-width: 560px) {
            width: 180px;
            height: 180px;

            img {
                width: 180px;
                height: 180px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        margin: 0 auto;
    }
`;

const SectionsComponent = ({section}) => {

    const programIMGsource = !!section.image.data && getAllSizesPNG(section.image.data.attributes);
    const Btn = section.Button
    
    return (
        <SectionWrapper id={section?.nav_title}>
            <SectionTemplate background={section.background_color}>
                {section?.title 
                        ? <HeaderTitle>
                                <h2>{section.title}</h2>
                            </HeaderTitle>
                        : <></> 
                }
                <BoxWrapper position={section?.image_position}>
                    {programIMGsource && (
                        <BoxOne>
                            <ResponsiveImage
                                medium={programIMGsource.medium}
                                small={programIMGsource.small}
                                large={programIMGsource.large}
                                alt={section.image.data?.alternativeText}
                            />
                        </BoxOne>
                    )}
                    <BoxTwo>
                        <ReactMarkdown>{section?.content}</ReactMarkdown>
                    </BoxTwo>
                </BoxWrapper>
                {Btn
                     && 
                    <Button background={Btn.Background_color}
                        id={Btn.id}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href=`${Btn.URL}`;
                        }}
                        >
                        {Btn.Title}
                    </Button>
                }
            </SectionTemplate>
        </SectionWrapper>   
    )

};

export default SectionsComponent;
