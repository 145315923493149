import React from "react";
import styled from "styled-components";
import { flexCenter, flexColumn } from "../mixins";

const Wrapper = styled.div`
    ${flexCenter};
    ${flexColumn};
    width: 100%;
    min-height: 600px;
    background-color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: 560px) {
        min-height: 300px;
    }
`;

const Title = styled.h2`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fz.XXXL};
    padding-bottom:10px;
`;

const Subtitle = styled.p`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fz.XL};
    font-weight:light !important;
`;

const NotFoundComponent = ({ message="Błąd 404"}) => (
    <Wrapper>
        <Title>{message}</Title>
        <Subtitle>Strona o podanym adresie nie istnieje.</Subtitle>
    </Wrapper>
);
export default NotFoundComponent;
