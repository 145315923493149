import React from "react";
import styled, { css } from "styled-components";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import { flexCenter, flexColumn } from "../../components/mixins";
import Button from "../../components/Button/Button.component";
import SpeakerJPG from "../../assets/img/speaker.jpg";
import { getAllSizesPNG } from "../../utils/imageUtils";

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    padding: 50px 30px;
    width: 100%;
    color: ${({ theme }) => theme.colors.grey};
    
    ${({ inverted }) =>
        inverted
            ? css`
                  background-color: ${({ theme }) => theme.colors.white};
              `
            : ""}
`;

const SpeakerWrapper = styled.div`
    ${flexCenter};
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.dark_grey};
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 30px;

    h4 {
        margin: 0 0 15px;
        font-weight: ${({ theme }) => theme.fw.medium};
    }

    @media screen and (max-width: 1000px) {
        ${flexColumn};
    }
`;

const LeftBox = styled.div`
        margin-right: 50px;
    & > img {
        width: 250px;
        height: 250px;
        margin: 15px auto;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.black};
        object-fit: cover;

        @media screen and (max-width: 560px) {
            width: 180px;
            height: 180px;
        }
    }

    @media screen and (max-width: 1000px) {
        margin-right: 0;
    }
`;

const RightBox = styled.div`
    
    p {
        line-height: 140%;
        text-align: justify;
    }

    p,
    ul,
    ol {
        font-size: ${({ theme }) => theme.fz.S};
        font-weight: ${({ theme }) => theme.fw.light};

        @media screen and (max-width: 560px) {
            font-size: ${({ theme }) => theme.fz.XXS};
        }
    }

    ul,
    ol {
        padding-left: 40px;

        @media screen and (max-width: 560px) {
            text-align: left;
            padding-left: 20px;
        }
    }

    ul {
        list-style: none;
        list-style-type: none;
    }

    ol li,
    ul li {
        position: relative;
        margin: 20px 0;
    }

    ul li {
        &::before {
            position: absolute;
            top: 0.4em;
            left: 0;
            transform: translateX(-1.2em);
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.grey};
        }
    }
`;


const SpeakerSection = ({speakers}) => {


    return (
        <SectionWrapper id="prelegent" inverted={true}>
            <HeaderTitle>
                {speakers?.length > 1 ? <h2>Szkolenie poprowadzą</h2> : <h2>Szkolenie poprowadzi</h2>}
            </HeaderTitle>
            {speakers?.length &&
                speakers.map((speaker,index)=>{
                    const sepakerData = speaker.attributes
                    const programIMGsource = !!sepakerData.image && getAllSizesPNG(sepakerData.image.data.attributes);
                    return (
                    <SpeakerWrapper index={index}>
                        {
                            sepakerData?.image &&                         
                                <LeftBox>
                                    <img src={programIMGsource.small}  alt="Prelegent" />
                                </LeftBox>
                        }
                        <RightBox>
                            {sepakerData.name && (
                                <h4>
                                    {sepakerData.name}
                                </h4>
                            )}
                            <p>
                                {sepakerData.bio}
                                <br /><br />
                                {sepakerData.bio2}
                                <br /><br />
                                {sepakerData.bio3}
                            </p>
                            <br /><br />
                        </RightBox>
                    </SpeakerWrapper>

                    )
                })
            }
        </SectionWrapper>
    );
};
export default SpeakerSection;
