import React, { useContext, useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { flexCenter, flexColumn, transitionMixin } from "./../components/mixins"
import { scrollTo } from "./../utils/scrollTo"
import Logo from "./../assets/img/semantika_logo_dark.png"
import { webinarContext } from "../providers/webinarTemplateProvider/webinar.provider"
import VideoComponent from "../components/VideoComponent/Video.component"

const LinkNavigationStyles = css`
    & {
        position: relative;
        overflow: hidden;
        margin: 0 15px;
        padding: 6px 0;
        font-size: ${({ theme }) => theme.fz.XXS};
        font-family: ${({ theme }) => theme.ff.Roboto};
        font-weight: ${({ theme }) => theme.fw.regular};
        color: ${({ theme }) => theme.colors.black};
        letter-spacing: 2px;
        text-decoration: none;
        text-transform: uppercase;
        ${transitionMixin("0.4s")};

        i {
            font-size: 1.4em;
        }

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(-105%, 0%);
            ${transitionMixin("0.4s")};
            width: 100%;
            height: 1.5px;
            background-color: ${({ theme }) => theme.colors.black};
        }

        &:hover {
            &::before {
                transform: translate(0%, 0%);
            }
        }

        @media screen and (max-width: 1250px) {
            font-size: ${({ theme }) => theme.fz.XXXS};
        }
    }
`

const InvertedNavStyles = css`
    background-color: transparent;
`
const NormalNavStyles = css`
    background-color: rgba(230, 230, 230, 0.5);
`

const invertNavStyles = (inverted) =>
    inverted ? InvertedNavStyles : NormalNavStyles

/* const NavContainer = styled.nav`
    ${flexCenter};
    position: relative;
    z-index: 10;
    width: 100%;
    margin: 80px auto 40px;
    padding: 8px 20px;
    border: 2px solid ${({ theme }) => theme.colors.black};

    ${({ inverted }) => invertNavStyles(inverted)};

    a {
        ${LinkNavigationStyles};

        &.active {
            &::before {
                transform: translate(0%, 0%);
            }
        }
    }

    @media screen and (max-width: 1250px) {
        padding: 8px 5px;
    }

    @media screen and (max-width: 1050px) {
        display: none;
    }
`;

const NavigationMenuStatic = ({ inverted }) => (
    <NavContainer inverted={inverted}>
        <Link to="/" onClick={scrollTo(0)}>
            <i className="fas fa-home"></i>
        </Link>
        <Link to="/o-nas/">O nas</Link>
        <Link to="/artykuly/">Artykuły</Link>
        <Link to="/#nowy_numer" onClick={scrollTo("#nowy_numer")}>
            Bieżący numer
        </Link>
        <Link to="/reklama/">Reklama</Link>
        <Link to="/#newsletter" onClick={scrollTo("#newsletter")}>
            Newsletter
        </Link>
        <Link to="#kontakt" onClick={scrollTo("#kontakt")}>
            Kontakt
        </Link>
    </NavContainer>
); */

const NavigationContainer = styled.nav`
    ${flexCenter};
    justify-content: space-between;
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    padding: 10px 100px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 1px 0px ${({ theme }) => theme.colors.black};

    @media screen and (max-width: 560px) {
        padding: 10px;
    }
`

const Menu = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    justify-content: flex-start;
    grid-column: 2/3;

    .mobile-scroll {
        height: 100%;
        ${flexCenter};
        justify-content: flex-start;
        grid-column: 2/3;
        position: relative;
        overflow: auto;
    }

    @media screen and (max-width: 1050px) {
        display: ${({ opened }) => (opened ? "flex" : "none")};
        box-sizing: content-box;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 15;
        transform: translateY(100%);
        width: 100%;
        height: auto;
        max-height: calc(100vh - 60px);
        overflow: hidden;
        padding: 0;
        border-top: 1px solid ${({ theme }) => theme.colors.grey};
        background-color: ${({ theme }) => theme.colors.white};

        .mobile-scroll {
            width: 100%;
            ${flexColumn};
        }
    }
`

const MenuLogo = styled.img`
    height: 100%;
    max-height: 60px;
    max-width: 140px;
    object-fit: contain;
    margin-right: 50px;

    @media screen and (max-width: 1050px) {
        display: none;
    }
`

const MobileLogo = styled(MenuLogo)`
    @media screen and (min-width: 1050px) {
        display: none;
    }

    @media screen and (max-width: 1050px) {
        margin: 0 auto 0 20px;
        display: block;
        height: 60px;
    }
`

const LinksContainer = styled.div`
    ${flexCenter};
    justify-content: flex-end;
    position: relative;
    width: 100%;

    a {
        ${LinkNavigationStyles};
        margin: 0 30px 0 0 !important;

        &.active {
            &::before {
                transform: translate(0%, 0%);
            }
        }
    }

    @media screen and (max-width: 1050px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 20px 40px;

        a {
            padding: 15px 20px;
            width: 100%;
            border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey};

            &::before {
                content: none;
            }
        }
    }
`

const CurrentNumberContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px;
    ${flexCenter};
    ${flexColumn};
    background-color: ${({ theme }) => theme.colors.light_grey2};

    & > * {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        text-align: center;
    }

    img {
        display: block;
        width: 80%;
        margin: 0 auto;
    }

    @media screen and (min-width: 1050px) {
        display: none;
    }
`

const Hamburger = styled.div`
    position: relative;
    width: 50px;
    height: 50px;

    span {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 60%;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.black};
        ${transitionMixin()};

        &:nth-of-type(1) {
            top: 16px;
        }
        &:nth-of-type(2) {
            top: 24px;
        }
        &:nth-of-type(3) {
            top: 32px;
        }
    }

    &.active {
        span {
            top: 50%;

            &:nth-of-type(1) {
                transform: translate(-50%, -50%) rotate(45deg);
            }
            &:nth-of-type(2) {
                opacity: 0;
            }
            &:nth-of-type(3) {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    @media screen and (min-width: 1050px) {
        display: none;
    }
`

export const NavigationMenuFloating = ({ logo }) => {
    //Hiding and showing menu depending on window.scrollY
    const [isOpen, setShown] = useState(false)

    const handleHamburgerClick = () => {
        isOpen ? setShown(false) : setShown(true)
    }

    const path = window.location.pathname

    // const { data:{slug,is_live,materials,speakers,partners} } = useContext(webinarContext)
    const {data:{attributes:{partners,speakers,vimeo,is_form_active=true,slug}}} = useContext(webinarContext)

    //If stream is ON - render stream link
    //If video is ON - render video + materials link

    const LinksContainerComponent = ({ urlPrefix }) => {
        const prefix = `/${urlPrefix}`

        return (
            urlPrefix && (
                <LinksContainer>
                    <Link to={`${prefix}/#start`} onClick={scrollTo(0)}>
                        <i className="fas fa-home"></i>
                    </Link>
                    <Link
                        to={`${prefix}/#webinar`}
                        onClick={scrollTo("#webinar")}
                    >
                        O webinarze
                    </Link>
                    <Link
                        to={`${prefix}/#program`}
                        onClick={scrollTo("#program")}
                    >
                        Program
                    </Link>
                    <Link
                        to={`${prefix}/#prelegent`}
                        onClick={scrollTo("#prelegent")}
                    >
                        {speakers.data?.length > 1 ? 
                        <span>Szkolenie prowadzą</span> 
                        : <span>Szkolenie prowadzi</span>}
                    </Link>
                    {partners?.length > 0 &&
                        <Link
                            to={`${prefix}/#partnerzy`}
                            onClick={scrollTo(`#partnerzy`)}
                        >
                        {partners?.length > 1 ? 
                        <span>Partnerzy wydarzenia</span> 
                        : <span>Partner wydarzenia</span>
                        }
                        </Link>                    
                    }


                    {is_form_active &&
                       <Link
                            to={`${prefix}/#formularz`}
                            onClick={scrollTo("#formularz")}
                            >
                           <b>Zapisz się</b>
                        </Link>
                    }
                    {vimeo?.vimeo_type === 'live' && <Link to={`${prefix}/stream/`}><b>Oglądaj</b></Link>}
                    {vimeo?.vimeo_type === 'video' && <a href={`${prefix}/nagranie/`}><b>Oglądaj</b></a>}

                </LinksContainer>
            )
        )
    }

    return (
        <NavigationContainer>
            <MobileLogo src={logo ? logo : Logo} onClick={scrollTo(0, 0)} />
            <Hamburger
                onClick={handleHamburgerClick}
                className={isOpen ? "active" : ""}
            >
                <span></span>
                <span></span>
                <span></span>
            </Hamburger>
            <Menu opened={isOpen}>
                <Link to="#start" onClick={scrollTo(0, 0)}>
                    <MenuLogo src={logo ? logo : Logo} />
                </Link>
                <LinksContainerComponent urlPrefix={slug && slug } />
            </Menu>
        </NavigationContainer>
    )
}

/* export default NavigationMenuStatic; */
