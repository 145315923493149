import React from "react"
import styled,{css} from "styled-components"
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component"
import { getAllSizesPNG } from "../../utils/imageUtils";
import {
    flexCenter,
    flexColumn,
    transitionMixin,
} from "../../components/mixins"

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    width: 100%;
    padding: 50px 30px;
    background-color: ${({ theme }) => theme.colors.white};
`

const LogosContainer = styled.div`
    width: 100%;
    ${flexCenter};
    ${flexColumn};
    color: ${({ theme }) => theme.colors.dark_grey};
    width: 80%;
    flex-wrap: wrap;
    ${({isRow})=>isRow?css`
        flex-direction:row;
    `:""}

    div.partner-wrapper {
        box-shadow: 5px 5px 15px -3px rgb(0 0 0 / 10%);
        display: flex;
        ${flexColumn};
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin:30px;
        padding:10px;
        ${transitionMixin()};
        &:hover {
            transform: scale(1.1);
        }
    }


    p {
        max-width: 900px;
        line-height: 160%;
        text-transform: uppercase;
        font-size:14px;
        margin: 0 auto;
        padding-bottom:15px;
        color:	#B0B0B0;
        font-weight: ${({ theme }) => theme.fw.light};
    }

    a {
        ${flexCenter};
        max-width: 200px;
        max-height: 100px;
        margin: 20px;
        ${transitionMixin()};

        img {
            width: 100%;
            height: 100%;
            height: 150px;
            min-width:200px;
            object-fit: contain;
            padding:20px;
        }

        // &:hover {
        //     transform: scale(1.1);
        // }
    }

    @media screen and (max-width: 560px) {
        ${flexColumn};

        a {
            margin: 20px auto;
        }
    }
`

const PatronsSection = ({partners}) => {

        return (
            <>
            <SectionWrapper id="partnerzy">           
                <HeaderTitle>
                    {partners?.length > 1 ? <h2>Partnerzy wydarzenia</h2> : <h2>Partner wydarzenia</h2>}
                </HeaderTitle>
            <LogosContainer isRow={true}>
            {partners?.length &&
                 partners.map((el,index)=>{
                    const { small } = getAllSizesPNG(el.Image.data.attributes);
                    return (
                    <div index={index} className="partner-wrapper" >
                            <a href={el.URL}  target="_blank" rel="noreferrer noopener">
                                <img alt="Partner konferencji" src={small} />
                            </a>
                        <p>{el.description}</p>
                    </div>
                    )
                })
            }
            </LogosContainer>
        </SectionWrapper>
        </>

        )

}



export default PatronsSection
