import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    *,*::before,*::after{
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    html{
        font-size:10px;

        @media screen and (max-width:1250px){
            font-size:9px;
        }

    }

    body{
        font-family:${({ theme }) => theme.ff.Roboto};
        background-color: ${({ theme }) => theme.colors.dark_white};
        width:100%;
        min-height: 100vh;
        padding-top: 70px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6{
        font-family:${({ theme }) => theme.ff.Roboto};
    }

    h1{
        font-size:${({ theme }) => theme.fz.XXL};
        font-weight:${({ theme }) => theme.fw.bold};

        &.big{
            font-size:${({ theme }) => theme.fz.XXXL};
        }

        &.center{
            text-align:center;
        }
    }
    h2{
        font-size:${({ theme }) => theme.fz.XL};
    }
    h3{
        font-size:${({ theme }) => theme.fz.L};
    }
    h4{
        font-size:${({ theme }) => theme.fz.M};
    }
    h5{
        font-size:${({ theme }) => theme.fz.S};
    }
    h6{
        font-size:${({ theme }) => theme.fz.XS};
    }

    p{
        font-size:${({ theme }) => theme.fz.XS};
        font-family:${({ theme }) => theme.ff.Roboto};
    }

    a{
      text-decoration: none;
        &:visited{
            color:${({ theme }) => theme.colors.black.white};
        }
    }

.page{
    &-enter{
        opacity:0;
    }

    &-enter-active{
        opacity:1;
        transition: opacity .4s;
        transition-delay: .6s;
    }

    &-exit {
        opacity:1;
    }

    &-exit-active {
        opacity: 0;
        transition:opacity .4s;
    }
}

`

const GlobalStyleComponent = (props) => <GlobalStyle />

export default GlobalStyleComponent
