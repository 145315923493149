import React, { createContext, useEffect, useState } from "react"
import TestPovider from "../../assets/test-provider.json"

const data = {
    id:0,
    attributes: {
        is_active:"",
        title: "",
        slug:"",
        date: "",
        time: "",
        subtitle:"",
        Buttons_background_color:"",
        Buttons_text_color:"",
        date_polish:"",
        description: "",
        is_form_active: "",
        is_live:true,
        sections:[],
        main_image:{
            data:{
                id:0,
                attributesMainImg:{
                    name:"",
                    alternativeText:"",
                    formats:{}
                }
            }
        },
        mobile_image:{
            data:{
                id:0,
                attributesMobileImg:{
                    name:"",
                    alternativeText:"",
                    formats:{}
                }
            }
        },
        partners:[],
        domain: {
            data:{
                id:1,
                attributes:{}
            }
        },
        speakers:[],
        certyficate: {},
        materials: {},
        files:[],            
        vimeo: {}
    }
}

const initialContextState = {
    data,
    setData: () => {},
    fetchData: () => {},
    loading: false,
    error: null,
}

export const webinarContext = createContext(initialContextState)

const WebinarTemplateProvider = ({ children }) => {
    const [templateState, setTemplateState] = useState(data)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    function setData(data) {
        setTemplateState((prev) => ({
            ...prev,
            ...data,
        }))
    }

    async function fetchData(slug) {
        setLoading(true)


        console.log(slug);
        const response = await fetch(
            `https://webinars.semantika.pl/api/webinars?filters[slug][$eq]=${slug}&populate=*`
        )

        if (!response.ok) {
            setError(new Error("Coś poszło nie tak. Źródło nieodnalezione."))
            return
        }

        const dataList = await response.json()
        const data = dataList.data

        const currentDomain = process.env.NODE_ENV === "production" ? window?.location?.host : "live.drogigminneipowiatowe.pl"

        if (data && data.length && data?.[0]) {
            const domain = data?.[0].attributes.domain.data.attributes.URL;
            if(currentDomain===domain){
                setLoading(false)
                setTemplateState(data[0])
            }
            else{
                setLoading(false)
                setError(
                    new Error(`Nie można znaleźć podanego webinaru - slug: ${slug}`)
                )
            }
        }
        else {
            setLoading(false)
            setError(
                new Error(`Nie można znaleźć podanego webinaru - slug: ${slug}`)
            )
            setTemplateState(TestPovider.data[0])
        }
        // setTemplateState(TestPovider.data[0])
        // setLoading(false)
        // console.log(TestPovider.data[0])

    }

    return (
        <webinarContext.Provider
            value={{ data: templateState, setData, fetchData, loading, error }}
        >
            {children}
        </webinarContext.Provider>
    )
}
export default WebinarTemplateProvider
