import React, { useContext } from "react"
import SEO from "../../components/SEO/SEO.component"
import HeaderSection from "../../sections/Header/Header.section"
import EventDateSection from "../../sections/EventDate/EventDate.section"
import { dateConvert } from "../../utils/dateUtils"
import Partner from "../../sections/PatronsSection/Patrons.section"
import SectionsComponent from "../../sections/Sections/Sections.component"
// import ProgramSection from "../../sections/ProgramSection/Program.section"
import SpeakerSection from "../../sections/SpeakerSection/Speaker.section"
import FormSection from "../../sections/FormSection/Form.section"
import { webinarContext } from "../../providers/webinarTemplateProvider/webinar.provider"
import NotFoundComponent from "../../components/404/404.component"
import SpinnerComponent from "../../components/Spinner/Spinner.component"

const TemplatePage = () => {
    console.log("IM RENDERED")
    //Template context
    const templateContext = useContext(webinarContext)

    if (templateContext.loading) {
        return <SpinnerComponent />
    }

    if (!templateContext.data) {
        return <NotFoundComponent />
    } else if (templateContext.data) {
        //Create refs from context

        const {
            attributes:{
                slug,
                is_active,
                date_polish,
                time,
                title,
                subtitle,
                description,
                is_form_active,
                Buttons_background_color,
                Buttons_text_color,
                main_image,
                mobile_image,
                sections,
                speakers,
                partners,
                vimeo
            }

        } = templateContext.data

        return (
            is_active ? 
            <SEO title={`Webinar: ${title}`} description={description}>
                <HeaderSection
                    images={{ main: main_image, mobile: mobile_image }}
                />
                <EventDateSection
                    eventDate={dateConvert({ date_polish, time })}
                    eventTitle={title}
                    is_form_active={is_form_active}
                    eventSubtitle={subtitle}
                    eventDescription={description}
                    vimeo={vimeo?.vimeo_type}
                    button={{Buttons_background_color,Buttons_text_color}}
                    
                />
                {sections?.length && sections.map((section,index) => <SectionsComponent key={index} section={section}/> )}
                <SpeakerSection speakers={speakers?.data}/>
                {partners?.length && <Partner partners={partners}/>}
                <FormSection
                    slug={slug}
                    isFormActive={is_form_active}
                    eventDate={dateConvert({ date_polish, time })}
                    webinarName={title}
                    vimeo={vimeo?.vimeo_type}
                    button={{Buttons_background_color,Buttons_text_color}}
                />
            </SEO> : <NotFoundComponent message="Błąd 404"/>
        )
    }

    return null
}

// is_form_active={is_form_active}

export default TemplatePage
