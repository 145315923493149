import React from "react";
import styled, { css } from "styled-components";
import { flexCenter, flexColumn, marginToChildren } from "../mixins";

const SectionWrapper = styled.div`
    ${flexCenter};
    ${flexColumn};
    padding: 50px 30px;
    width: 100%;
    color: ${({ theme }) => theme.colors.dark_grey};
    ${({ background }) =>
    background
        ? css`
            background-color: ${background};
          `
        : css`
            background-color: #fff;
        `
    }
    & > p,
    & > h1,
    & > h2,
    & > h3,
    & > h4,
    & > h5,
    & > h6 {
        color: inherit;
        max-width: 800px;
        margin: 0 auto 15px;
        text-align: justify;
    }

    p,
    ul,
    ol {
        font-size: ${({ theme }) => theme.fz.S};
        font-weight: ${({ theme }) => theme.fw.light};
        line-height: 150%;

        @media screen and (max-width: 560px) {
            font-size: ${({ theme }) => theme.fz.XS};
        }
    }

    ul,
    ol {
        list-style: none;
        list-style-type: none;
        max-width: 900px;
        line-height: 150%;
        margin: 0 auto;
        padding-left: 40px;

        @media screen and (max-width: 560px) {
            text-align: left;
            padding-left: 20px;
        }
    }

    ol li,
    ul li {
        position: relative;
        margin: 20px 0;
    }

    ul li {
        &::before {
            position: absolute;
            top: 0.4em;
            left: 0;
            transform: translateX(-1.2em);
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.dark_grey};
        }
    }

    & img {
        width: 220px;
        height: 220px;
        margin: 15px auto;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.black};

        &.title-border {
            width: initial;
            height: initial;
            margin: initial;
            border-radius: initial;
            border: initial;
        }

        @media screen and (max-width: 560px) {
            width: 180px;
            height: 180px;
        }
    }

    @media screen and (max-width: 560px) {
        text-align: center;
    }
`;

const SectionTemplate = ({background,children,inverted }) => (
    <SectionWrapper background={background} inverted={inverted}>{children}</SectionWrapper>
);
export default SectionTemplate;
