import React from "react";
import { Helmet } from "react-helmet-async";

const SEOContainer = ({
    children,
    title = "Title",
    description = "Description",
    author,
    keywords,
}) => (
    <>
        <Helmet title={title}>
            <meta name="description" content={description} />
            {author && <meta name="author" content={author} />}
            {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>
        {children}
    </>
);
export default SEOContainer;
