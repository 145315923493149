import React from "react";
import { Route, Switch } from "react-router-dom";
import LayoutComponent from "./layout";
import MainPage from "./pages/MainPage/MainPage.page";
import WebinarTemplateProvider from "./providers/webinarTemplateProvider/webinar.provider";

function App() {
    return (
        <WebinarTemplateProvider>
            <LayoutComponent>
                <Switch>
                    <Route path="/" component={MainPage} />
                </Switch>
            </LayoutComponent>
        </WebinarTemplateProvider>
    );
}

export default App;
