import React,{useContext} from "react";
import styled from "styled-components";
import { flexCenter, flexColumn, flexRow } from "../mixins";
import SEO from "../SEO/SEO.component.js";
import {webinarContext} from "../../providers/webinarTemplateProvider/webinar.provider";
import NotFoundComponent from "../../components/404/404.component"

const StreamWrapper = styled.div`
    ${flexCenter};
    width: 100%;
    height: calc(100vh - 80px);

    @media screen and (max-width: 1080px) {
        ${flexColumn};
        height: auto;
    }
`;

const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    border: none;
    border-left: 1px solid ${({ theme }) => theme.colors.black};

    @media screen and (max-width: 1080px) {
        width: 100%;
        min-height: 450px;
        border: none;
    }
`;

const StreamBox = styled.iframe`
    width: 70%;
    height: 100%;
    border: none;

    @media screen and (max-width: 1080px) {
        width: 100%;
        min-height: 400px;
        margin: 20px 0;
    }

    @media screen and (max-width: 560px) {
        width: 100%;
        min-height: 300px;
        margin: 20px 0;
    }

    @media screen and (max-width: 400px) {
        width: 100%;
        min-height: 200px;
        margin: 20px 0;
    }
`;

const ChatBox = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    border-left: 1px solid ${({ theme }) => theme.colors.black};

    @media screen and (max-width: 1080px) {
        width: 100%;
        min-height: 450px;
        border: none;
    }
    
`;

const ChatLink = styled.a`
    width: 80%;
    margin: 10px auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    min-width: 150px;
    padding: 5px 20px;
    margin: 10px auto;
    border-radius: 20px;
    border: none;
    background-color: #3BCC72;
    color: #FFFFFF;
    font-size: 1.6rem;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
`;

const StreamComponent = () => {

    const {data:{attributes:{vimeo}}} = useContext(webinarContext)

    if(vimeo.vimeo_type !== "live"){
        return <NotFoundComponent message="Błąd 404"/>
    }

    return (
        <SEO title="Webinar - LIVE" description="Strona Live webinaru">
            {vimeo.vimeo_number ? 
            (
            <>
                <StreamWrapper>
                <StreamBox
                    src={`https://vimeo.com/event/${vimeo.vimeo_number}/embed`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                    width="1920"
                    height="1080"
                />
                <ChatWrapper>
                    {/*<ChatLink href={`https://docs.google.com/forms/d/1WTUUEFr2lhw7LumjLnj2pYs2mEK-M2VetI-9-hq44S4/edit`} target={`_blank`}>Certyfikat</ChatLink>*/}
                    <ChatBox src={`https://vimeo.com/event/${vimeo.vimeo_number}/chat/interaction/`} />
                </ChatWrapper>



            </StreamWrapper>
            </>): <NotFoundComponent message="Nie odnaleziono źródła."/>}
        </SEO>
    );
}
export default StreamComponent;
