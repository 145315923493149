const theme = {
    colors: {
        black: "#191A1C",
        light_black: "#151718",
        grey: "#8C8C8D",
        dark_grey: "#555555",
        light_grey: "#F2F2F2",
        light_grey2: "#F1F0F6",
        white: "#FFFFFF",
        dark_white: "#F5F5F5",
        red: "#E63946",
        green: "#3bcc72",
        pink: "#E7015E",
        dark_pink: "#A90A62",
        navyBlue: "#3B1D82",
        light_blue: "#00b4d8",
    },

    fz: {
        XXXL: "4.2rem",
        XXL: "3.4rem",
        XL: "3.2rem",
        L: "3rem",
        M: "2.2rem",
        S: "1.8rem",
        XS: "1.6rem",
        XXS: "1.4rem",
        XXXS: "1.2rem",
    },
    ff: {
        Roboto: "Roboto,Arial,sans-serif",
    },
    fw: {
        light: "300",
        regular: "400",
        medium: "500",
        bold: "700",
    },
};

export default theme;
