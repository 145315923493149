import React from "react";
import styled, { css } from "styled-components";
import { flexCenter, transitionMixin } from "../mixins";

const Button = styled.button`
    ${flexCenter};
    display: inline-flex;
    min-width: 150px;
    padding: 15px 30px;
    margin: 25px auto;
    border-radius: 20px;
    border: none;
    ${({ background }) =>
    background
        ? css`
            background-color: ${background};
          `
        : css`
            background-color: ${({ theme }) => theme.colors.green};
    `}
    ${({ color }) =>
    color
        ? css`
            color: ${color};
          `
        : css`
            color: ${({ theme }) => theme.colors.white};
    `}
    font-size: ${({ theme }) => theme.fz.XS};
    font-weight: ${({ theme }) => theme.fw.medium};
    cursor: pointer;
    ${transitionMixin()};

    &:hover {
        background-color: ${({ theme }) => theme.colors.light_blue};
        color: ${({ theme }) => theme.colors.white};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.colors.dark_grey};

        &:hover {
            background-color: ${({ theme }) => theme.colors.dark_grey};
        }
    }
    
`;

export default Button;
