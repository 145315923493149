import React from "react";
import styled from "styled-components";
import { flexCenter, flexColumn } from "../mixins";

const Wrapper = styled.div`
    ${flexCenter};
    ${flexColumn};
    width: 100%;
    min-height: 600px;

    @media screen and (max-width: 560px) {
        min-height: 300px;
    }
`;

const SpinnerBox = styled.i`
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fz.XXXL};
    animation: spin 1.5s infinite linear;
    margin: 15px auto;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const SpinnerComponent = (props) => (
    <Wrapper>
        <SpinnerBox className="fas fa-spinner" />
        <h2>Ładowanie</h2>
    </Wrapper>
);
export default SpinnerComponent;
