import React from "react";

const ResponsiveImage = ({ large, medium, small, alt, ...rest }) => (
    <picture>
        {large && <source srcSet={large} media="(min-width:1250px)" />}
        {medium && <source srcSet={medium} media="(min-width:960px)" />}
        <img {...rest} src={small} alt={alt} />
    </picture>
);
export default ResponsiveImage;
