import React, { useState } from "react"
import styled, { css } from "styled-components"
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component"
import { flexCenter, flexColumn } from "../../components/mixins"
import Button from "../../components/Button/Button.component"
import ReactPixel from "react-facebook-pixel"
import { Link } from "react-router-dom"

const FormWrapper = styled.div`
    width: 100%;
    padding: 50px 30px;
    background-color: ${({ theme }) => theme.colors.light_grey};
    text-align: center;
`

const Form = styled.form`
    ${flexCenter};
    ${flexColumn};
    flex-wrap: wrap;
    width: 100%;
    max-width: 900px;
    margin: 0px auto 20px;

    input, select {
        margin: 10px;
        padding: 5px 10px;
        min-width: 350px;
        border: none;
        font-family: ${({ theme }) => theme.ff.Roboto};
        font-size: ${({ theme }) => theme.fz.XS};
        color: ${({ theme }) => theme.colors.black};

        &::placeholder {
            font-family: ${({ theme }) => theme.ff.Roboto};
            font-size: ${({ theme }) => theme.fz.XS};
            color: ${({ theme }) => theme.colors.black};
        }
    }

    @media screen and (max-width: 560px) {
        input {
            min-width: unset;
            width: 100%;
            margin: 10px auto;
        }
    }
`

const OrderTitle = styled.h6`
    margin: 50px 0 0;
    font-weight: ${({ theme }) => theme.fw.regular};
`

const Message = styled.h6`
    margin: 15px auto 0;
    color: ${({ theme }) => theme.colors.green};

    ${({ variant }) =>
        variant === "error"
            ? css`
                  color: ${({ theme }) => theme.colors.red};
              `
            : ""}
`

const P = styled.p`
    margin: 10px auto 0;
    font-size: ${({ theme }) => theme.fz.S};
    color: ${({ theme }) => theme.colors.grey};

    ${({ big }) =>
        big
            ? css`
                  font-size: ${({ theme }) => theme.fz.L};
              `
            : ""}

    ${({ thick }) =>
        thick
            ? css`
                  font-weight: ${({ theme }) => theme.fw.bold};
                  color: ${({ theme }) => theme.colors.dark_grey};
              `
            : ""}
            
    ${({ flex }) =>
        flex
            ? css`
                  ${flexCenter};
              `
            : ""}

    ${({ short }) =>
        short
            ? css`
                  max-width: 1000px;
                  margin-left: auto;
                  margin-right: auto;
              `
            : ""}
            
            ${({ light, theme }) =>
        light
            ? css`
                  font-weight: ${theme.fw.light};
              `
            : ""}

            i {
        font-size: 0.7em;
    }
`

const EventDate = styled.h4`
    margin-top: 15px;
    font-weight: ${({ theme }) => theme.fw.regular};
`

const FormSection = ({
    slug,
    isFormActive = false,
    vimeo,
    eventDate,
    webinarName,
    button
}) => {

    const [error, setError] = useState(null)
    const [responseResult, setResponseResult] = useState({
                    status: null,
                    statusText: null
    })
    const [prohibitSending, setProhibition] = useState(false)

    const {Buttons_background_color,Buttons_text_color} = button

    const [data, setData] = useState({
        id: slug,
        name: "",
        surname: "",
        email: "",
        phone: "",
        specialization: "",
        voivodeship: "",
    })

    if (!isFormActive || vimeo?.vimeo_type) {
        return null
        // (
        //     <FormWrapper id="formularz">
        //         <HeaderTitle>
        //             <h2>Formularz zapisu</h2>
        //         </HeaderTitle>
        //         <h4>Brak możliwości zapisów.</h4>
        //     </FormWrapper>
        // )
    }

    const handleChange = (e) => {
        const prop = e.currentTarget.name.trim()
        const value = e.currentTarget.value.trim()
        setData((prevData) => ({ ...prevData, [prop]: value }))
    }


    const validate = (input) => {

        const inputTrimmed = input.trim().replace(/\s+/g, '').replace(/[-]/g, '').replace(/[(]/g, '').replace(/[)]/g, '')
        const rightFormat = /^([\+]?)([(]?)(\d{1,6}?)([)]?)([-\s\.]?)(\d{1,6}?)([-\s\.]?)(\d{1,6})([-\s\.]?\d{1,6})$/im
        if(!inputTrimmed.match(rightFormat) || inputTrimmed.length > 13){
            return Error(
                "Wprowadź poprawny numer telefonu"
            )
        }
        return true
    }

    const validateStr = (input) => {

        const inputTrimmed = input.trim().replace(/\s+/g, '')
        const numbers = /\d/g
        if( inputTrimmed.match(numbers) || inputTrimmed.length < 2){
            return Error(
                "Wprowadź poprawne `imię` i nazwisko"
            )
        }
        return true
    }

    const clearErrors = () => {
        setError(null)
        setResponseResult(null)
    }
    console.log(window.location.href)
    const currentDomain =
    process.env.NODE_ENV === "production"
        ? window?.location?.href.includes("pacjent-onkologiczny-pod-opieka-dietetyka") ? true : false
        : null

    console.log(currentDomain)

    const handleSubmit = async (e) => {
        e.preventDefault()
        clearErrors()
        setProhibition(true)

        const phoneError = validate(data.phone)
        const nameError = validateStr(data.name)
        const surNameError = validateStr(data.surname)

        if (phoneError instanceof Error) {
            setError(phoneError)
            setProhibition(false)
            return
        }

        if(nameError instanceof Error){
            setError(nameError)
            setProhibition(false)
            return
        }

        if(surNameError instanceof Error){
            setError(surNameError)
            setProhibition(false)
            return
        }

        try {
            const { id, name, surname, email, phone, specialization, voivodeship } = data

            const body = JSON.stringify({
                id: id,
                firstname: name,
                lastname: surname,
                email: email,
                phonenumber: phone,
                specialization: specialization,
                voivodeship: voivodeship
            })



            const res = await fetch(
                "https://api.semantika.pl/api/webinars/registration",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body,
                }
            )

            console.log("RESPONSE FROM FORM: ", res)

            if (res.ok) {
                setResponseResult({
                    status: res.status,
                    statusText: res.statusText,
                })
                ReactPixel.track("Purchase", {
                    content_name: webinarName,
                })
            } else {
                setResponseResult({
                    status: res.status,
                    statusText: res.statusText,
                })
            } if (res.status != "201") {
                setProhibition(false)
                }

            } 

            catch (e) {
                console.log(e, "ERROR FROM FORM")

                setResponseResult({
                    status: 500,
                    statusText: "Coś poszło nie tak. Spróbuj ponownie później.",
            })
        }
    }

    return (
        <FormWrapper id="formularz">
            <HeaderTitle>
                <h2>Formularz zapisu</h2>
            </HeaderTitle>

            <P>SZKOLENIE ONLINE</P>
            <P flex big thick>
                <i className="fa fa-certificate" aria-hidden="true"></i>
                &nbsp;BEZPŁATNE&nbsp;
                <i className="fa fa-certificate" aria-hidden="true"></i>
            </P>

            {eventDate && !vimeo && <EventDate>{eventDate}</EventDate>}
            <OrderTitle>Dane uczestnika</OrderTitle>

            <Form onSubmit={handleSubmit}>
                <div>
                    <input
                        required
                        type="text"
                        name="name"
                        placeholder="Imię"
                        onChange={handleChange}
                    />
                    <input
                        required
                        type="text"
                        name="surname"
                        placeholder="Nazwisko"
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <input
                        required
                        type="email"
                        name="email"
                        placeholder="Adres e-mail"
                        onChange={handleChange}
                    />
                    <input
                        required
                        type="tel"
                        minLength="7"
                        maxLength="17"
                        name="phone"
                        placeholder="Numer telefonu"
                        onChange={handleChange}
                    />
                    {window.location.href.includes('kruszywa-wapienne-w-infrastrukturze') &&
                    <div>
                        <select
                            required
                            type="select"
                            name="voivodeship"
                            onChange={handleChange}
                        >
                            <option value="" disabled selected>Wybierz województwo...</option>
                            <option value="dolnośląskie">dolnośląskie</option>
                            <option value="kujawsko-pomorskie">kujawsko-pomorskie</option>
                            <option value="lubelskie">lubelskie</option>
                            <option value="lubuskie">lubuskie</option>
                            <option value="łódzkie">łódzkie</option>
                            <option value="małopolskie">małopolskie</option>
                            <option value="mazowieckie">mazowieckie</option>
                            <option value="opolskie">opolskie</option>
                            <option value="podkarpackie">podkarpackie</option>
                            <option value="podlaskie">podlaskie</option>
                            <option value="pomorskie">pomorskie</option>
                            <option value="śląskie">śląskie</option>
                            <option value="świętokrzyskie">świętokrzyskie</option>
                            <option value="warmińsko-mazurskie">warmińsko-mazurskie</option>
                            <option value="wielkopolskie">wielkopolskie</option>
                            <option value="zachodniopomorskie">zachodniopomorskie</option>
                        </select>
                    </div>
                    }
                </div>

                {error && <Message variant="error">{error.message}</Message> }
                {responseResult && (
                    <Message
                        variant={responseResult.status == "201" ? "" : "error"}
                    >
                        {responseResult.statusText}
                    </Message>
                )}
                { responseResult && console.log(responseResult.status)}
                    <>
                    {
                        responseResult && responseResult.status != "201" &&
                        <Button 
                            background={Buttons_background_color} 
                            type="submit" 
                            disabled={prohibitSending}
                            color={Buttons_text_color}
                        >
                            Zapisz się
                        </Button>
                    }



                    </>
            </Form>
        </FormWrapper>
    )
}
export default FormSection




        // const number = +input
        // if (typeof number !== "number") {
        //     return Error(
        //         "Wprowadź poprawny numer telefonu"
        //     )
        // }
        
        // if (input.includes("+")) {
        //     return Error(
        //         "Poprawny format numeru telefonu: XXXXXXXXX. (Bez numeru kierunkowego +48)"
        //     )
        // }
        // const number = +input
        // if (typeof number !== "number" || number.toString().length !== 9) {
        //     return Error(
        //         "Format numeru telefonu: XXXXXXXXX. Numer nie powinien zaczynać się od zera."
        //     )
        // }