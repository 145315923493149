import React,{useContext} from "react"
import styled from "styled-components"
import { flexCenter, flexColumn } from "../mixins"
import SEO from "../SEO/SEO.component"
import HeaderTitle from "../HeaderTitle/HeaderTitle.component"
import Button from "../Button/Button.component"
import ArticleTestPNG from "../../assets/img/article_test.jpg"
import {webinarContext} from "../../providers/webinarTemplateProvider/webinar.provider"
import NotFoundComponent from "../404/404.component";
import { Link } from 'react-router-dom';
import Partner from "../../sections/PatronsSection/Patrons.section"





const VideoWrapper = styled.div`
    ${flexCenter};
    ${flexColumn};
    min-height: 600px;
    height:auto;
    padding:40px 0 0;
    width: 100%;
`

const VideoBox = styled.iframe`
    display: block;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    min-height: 500px;
    border: none;

    @media screen and (max-width: 1080px) {
        min-height: 400px;
        margin: 20px 0;
    }

    @media screen and (max-width: 560px) {
        width: 100%;
        min-height: 300px;
        margin: 20px 0;
    }

    @media screen and (max-width: 400px) {
        width: 100%;
        min-height: 200px;
        margin: 20px 0;
    }
`

const CenterBox = styled.div`
    ${flexCenter};
    width: 100%;
    margin:0 20px 20px auto;
`


const Container = styled.div`

    width: 100%;
    display:flex;
    flex-direction:row;
    max-width:900px;
    padding:40px 0;
    ${flexCenter};
    a{
        text-decoration:none;
        color:#fff;
    }
    
    @media screen and (max-width: 680px){
        flex-direction:column;
        justify-content:center;
        align-items:center;
    }
`




const DownloadButton = ({ fileToDownload,download,name,bgColor }) => 
    fileToDownload && (
        <>
            <CenterBox>
                <Button background={bgColor} as={"a"} href={fileToDownload} download>
                    {name}
                </Button>
            </CenterBox>
        </>
    )
    

const VideoComponent = () => {

    const {data:{
            attributes:{
                vimeo,files,partners
                
    }}} = useContext(webinarContext)


    if(vimeo.vimeo_type !== "video"){
        return <NotFoundComponent message="Błąd 404"/>
    }
    
 


    return (
        <SEO title="Webinar - Nagranie" description="Strona nagrania webinaru">
            {vimeo.vimeo_number ? (
                <>
                <VideoWrapper>
                    <HeaderTitle>
                    <h2>Nagranie webinaru</h2>
                    
                    </HeaderTitle>

                        <VideoBox
                            src={`https://player.vimeo.com/video/${vimeo.vimeo_number}`}
                            width="1920"
                            height="1080"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                        />
                        <Container>
                            {files?.map((el,index)=>{
                                return (
                                        <DownloadButton
                                            bgColor={el?.button.Background_color}
                                            key={index} 
                                            index={index}
                                            fileToDownload={`https://strapi.semantika.pl${el?.file.data.attributes.url}`}
                                            download={el?.file.data.attributes.name}
                                            name={el?.button.Title}
                                        />
                                )
                            })}
                        </Container>
                        {/*{vimeo?.vimeo_number == 683274601 && partners?.length && <Partner partners={partners}/>}*/}
                </VideoWrapper>
            </>
            ) : <NotFoundComponent message="Błąd 404"/>}
        </SEO>
    )
}

export default VideoComponent
