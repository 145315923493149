import styled, { css } from "styled-components";
import {
    flexCenter,
    flexColumn,
    marginToChildren,
    transitionMixin,
} from "../components/mixins";
import GWSlogoPNG from "./../assets/img/semantika_logo.png";
import React, { useContext, useState } from "react"
import { webinarContext } from "../providers/webinarTemplateProvider/webinar.provider";


const FooterContainer = styled.div`
    width: 100%;
    padding: 50px 40px;
    background-color: ${({ theme }) => theme.colors.black};
`;

const TopBlock = styled.div`
    ${flexCenter};
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
    padding: 20px 0;
    margin: 0 auto;

    img {
        max-width: 250px;
    }

    @media screen and (max-width: 1250px) {
        width: 90%;
    }

    @media screen and (max-width: 850px) {
        ${flexColumn};
        align-items: center;
        width: 100%;
        ${marginToChildren()};
    }
`;

const BottomBlock = styled(TopBlock)`
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
`;

const Content = styled.div`
    ${flexColumn};
    ${({ dir }) =>
        dir === "row"
            ? css`
                  flex-direction: row;
              `
            : ""};
    align-items: flex-start;
    ${marginToChildren("0 0 10px")};
    color: ${({ theme }) => theme.colors.dark_white};

    p {
        line-height: 160%;
        font-family: ${({ theme }) => theme.ff.Roboto};

        &.center {
            margin: inherit auto;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
        ${transitionMixin()};

        &:hover {
            color: ${({ theme }) => theme.colors.grey};
        }
    }

    @media screen and (max-width: 850px) {
        min-width: 320px;
        padding: 20px 0 !important;
    }

    @media screen and (max-width: 560px) {
        align-items: center;
        text-align: center;
    }
`;

const Title = styled.h6`
    font-family: ${({ theme }) => theme.ff.Roboto};
    padding-bottom: 2px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;



const FooterComponent = () => {

    const webinarTemplate = useContext(webinarContext);
    const {attributes:{Footer}} = webinarTemplate.data

    return (
            <FooterContainer id="kontakt">
                <TopBlock>
                    <Content>
                        <picture>
                            <img
                                src={GWSlogoPNG}
                                alt="Logo - Grupa Wydawnicza Semantika"
                            />
                        </picture>
                        <p>
                            Grupa Wydawnicza Semantika Sp. z o.o.
                            <br />
                            ul. Żuławska 10, 60-412 Poznań
                            <br />
                            KRS: 0000470869
                            <br />
                            Sąd Rejonowy w Poznaniu
                            <br />
                            VIII Wydział Gospodarczy KRS
                            <br />
                            NIP: 7773232650, REGON: 302483298
                        </p>
                    </Content>
                    <Content>
                        <Title>Redakcja</Title>
                        <a href="mailto:biuro@semantika.pl">
                            <p>
                                <i className="fas fa-envelope" aria-hidden="true"></i>
                                &nbsp; biuro@semantika.pl
                            </p>
                        </a>
                        <a href="tel:+48618471134">
                            <p>
                                <i
                                    className="fas fa-phone-square-alt"
                                    aria-hidden="true"
                                ></i>
                                &nbsp; +48 61 610 30 60
                            </p>
                        </a>
                        <a
                            href="https://semantika.pl/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <p>
                                <i
                                    className="fas fa-globe-europe"
                                    aria-hidden="true"
                                ></i>
                                &nbsp; www.semantika.pl
                            </p>
                        </a>
                    </Content>
                    <Content>

                        {
                            Footer?.length ? 
                                <>
                                    <Title>Zobacz również</Title>
                                    {Footer.map((link,index)=>
                                    <a index={index} href={`${link.URL}`} target="__blank">
                                        <p>{link.Name}</p>
                                    </a>)}
                                </> : undefined
                        }
                    </Content>
                </TopBlock>
                <BottomBlock>
                    <Content>
                        <p>
                            &copy; 2022 Grupa Wydawnicza Semantika Sp. z o.o. Wszelkie
                            prawa zastrzeżone.
                        </p>
                    </Content>
                </BottomBlock>
            </FooterContainer>
        )
            
};

export default FooterComponent;
