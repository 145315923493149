import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import App from "./App";
import theme from "./providers/themeProvider/theme";
import { BrowserRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider >
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
