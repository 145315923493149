import React from "react";
import styled, { css } from "styled-components";
import { flexCenter, flexColumn, marginToChildren } from "../mixins";
import TitleBorder from "./../../assets/img/separator.png";

const Header = styled.header`
    ${flexCenter};
    ${flexColumn};
    margin: 30px auto;
    ${({ color }) =>
    color
        ? css`
        color: ${({ theme }) => theme.colors.black};
          `
        : css`
        color: ${({ theme }) => theme.colors.dark_grey};
    `}


    & > * {
        color: inherit;
    }

    span {
        ${flexCenter};
        max-width: 100px;

        img {
            max-width: 180px;
            object-fit: contain;
        }
    }
`;

const HeaderTitle = ({ children,color }) => (
    <Header color={color}>
        {children}
        <span>
            <img
                className="title-border"
                src={TitleBorder}
                alt="Title border"
            />
        </span>
    </Header>
);
export default HeaderTitle;
