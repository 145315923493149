import React from "react";
import styled, { css } from "styled-components";
import HeaderTitle from "../../components/HeaderTitle/HeaderTitle.component";
import { flexCenter, flexColumn } from "../../components/mixins";
import WebinarPNG from "../../assets/img/webinar.png";
import Button from "../../components/Button/Button.component";
import { scrollTo } from "../../utils/scrollTo";

const SectionWrapper = styled.section`
    ${flexCenter};
    ${flexColumn};
    text-align: center;
    width: 100%;
    padding: 50px 30px;
    background-color: ${({ theme }) => theme.colors.white};

    @media screen and (max-width: 560px) {
        padding: 30px 15px;
    }
`;

const DateContainer = styled.div`
    ${flexCenter};
    flex-wrap: wrap;
    width: 100%;
`;

const DateBox = styled.div`
    ${flexCenter};
    ${flexColumn};
    min-width: 220px;
    padding: 25px 40px;
    color: ${({ theme }) => theme.colors.light_black};
    background-color: #e3e3e3;
    margin: 15px 5px;
    text-transform: uppercase;

    ${({ inverted }) =>
        inverted
            ? css`
                  color: ${({ theme }) => theme.colors.white};
                  background-color: ${({ theme }) => theme.colors.pink};
              `
            : ""}

    p {
        font-size: ${({ theme }) => theme.fz.M};
        font-weight: ${({ theme }) => theme.fw.bold};
    }

    span {
        font-size: ${({ theme }) => theme.fz.XS};
        font-weight: ${({ theme }) => theme.fw.regular};
        margin-top: 5px;
    }

    @media screen and (max-width: 560px) {
        text-align: center;
        min-width: unset;
        width: 100%;

        p {
            font-size: ${({ theme }) => theme.fz.S};
        }

        span {
            font-size: ${({ theme }) => theme.fz.XXS};
        }
    }
`;

const WebinarImage = styled.img`
    max-width: 100px;
    margin: 15px auto 5px;
    filter: opacity(0.65);
`;

const H3 = styled.h3`
    margin: 0 auto 15px;
`;

const H4 = styled.h4`
    margin: 0 auto 15px;
`;

const P = styled.p`
    margin: 10px auto 0;
    font-size:20px;
    max-width:1000px;
    font-size: ${({ theme }) => theme.fz.M};
    color: ${({ theme }) => theme.colors.dark_grey};

    ${({ big }) =>
        big
            ? css`
                  font-size: ${({ theme }) => theme.fz.L};
              `
            : ""}

    ${({ thick }) =>
        thick
            ? css`
                  font-weight: ${({ theme }) => theme.fw.bold};
                  color: ${({ theme }) => theme.colors.dark_grey};
              `
            : ""}
            
    ${({ flex }) =>
        flex
            ? css`
                  ${flexCenter};
              `
            : ""}

    ${({ short }) =>
        short
            ? css`
                  max-width: 1000px;
                  margin-left: auto;
                  margin-right: auto;
              `
            : ""}
            
            ${({ light, theme }) =>
        light
            ? css`
                  font-weight: ${theme.fw.light};
              `
            : ""}

            i {
        font-size: 0.7em;
    }
    
`;

const EventDateSection = ({is_form_active=true,eventDate, eventTitle, eventDescription,button,eventSubtitle,vimeo }) => {

    const {Buttons_background_color,Buttons_text_color} = button

    return(
    <SectionWrapper id="webinar">
        <HeaderTitle color={'black'}>
        {eventTitle && <h2>{eventTitle}</h2>}
        </HeaderTitle>
        {eventSubtitle && <H4>{eventSubtitle}</H4>}
        {eventDescription && (
            <P short light>
                {eventDescription}
            </P>
        )}

        <WebinarImage src={WebinarPNG} alt="Ikona Webinaru" />

        {is_form_active && <H4>{eventDate}</H4>}
        <P>SZKOLENIE ONLINE</P>
        {is_form_active &&
        <P flex big thick>
            <i className="fa fa-certificate" aria-hidden="true"></i>
            &nbsp;BEZPŁATNE&nbsp;
            <i className="fa fa-certificate" aria-hidden="true"></i>
        </P>
        }
        {/*{!is_form_active &&*/}
        {/*        <Button */}
        {/*        background={Buttons_background_color} */}
        {/*        color={Buttons_text_color}*/}
        {/*        onClick={(e) => {*/}
        {/*            e.preventDefault();*/}
        {/*            window.location.href='https://sklep.semantika.pl/pl/c/Szkolenia-video/38';*/}
        {/*        }}*/}
        {/*        >OGLĄDAJ</Button>*/}

        {/*}*/}
        {is_form_active &&
        <Button
            background={Buttons_background_color}
            color={Buttons_text_color}
            onClick={scrollTo("#formularz")}

        >ZAPISZ SIĘ</Button>

        }
    </SectionWrapper>)
};
export default EventDateSection;
