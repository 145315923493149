import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export const scrollTo =
    (ref, offset = 80) =>
    () =>
        gsap.to(window, {
            scrollTo: { y: ref, offsetY: offset },
            duration: 0.5,
        });
